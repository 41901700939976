@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap");

.poppins {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../src/assets/font/FontsFree-Net-Acumin-Pro-Book.ttf");
}

.acumin_pro {
  font-family: "Acumin Pro", sans-serif;
}

.arial {
  font-family: Arial, sans-serif;
}

.lora {
  font-family: "Lora", serif;
}

.joinbtn_shadow {
  box-shadow: 0px 9px 65px 0px rgba(35, 161, 152, 0.4);
}

/* ADMIN PANEL */
/* CSS for scrollbar modification */
.admin-panel {
  /* Set overflow to auto to enable the scrollbar */
  overflow-x: auto;
}
table {
  border-collapse: collapse !important;
}

td {
  padding-top: 2.5em !important;
  padding-bottom: 2.5em !important;
}

/* PAGINATION */

.pagination__page {
  background-color: #23a198;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__page-link {
  cursor: pointer;
  background-color: #23a198;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.pagination__page:hover {
  cursor: pointer;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 18px;
}
.pagination__link--active {
  background: #23a198;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

/* Customize scrollbar styles */
::-webkit-scrollbar {
  width: 8px !important; /* Set scrollbar height */
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5) !important; /* Thumb color */
  border-radius: 4px !important; /* Rounded corners */
}

/* Remove scrollbar arrows */
::-webkit-scrollbar-button {
  display: none !important;
}

/* SLICK SLIDERS */
.slick-track {
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center !important;
}
.slick-slide > div {
  display: flex !important;
}

.slick-dots li.slick-active button:before {
  color: #23a198 !important;
  font-size: 20px !important;
}
.slick-dots li button:before {
  font-size: 12px !important;
}
.slick-dots li {
  margin: 0px 2px !important;
}
/* ------ */

/* FAQ */
.bg-gray-100 {
  background-color: unset !important;
}
.faq-img {
  filter: drop-shadow(0px 30px 80px rgba(35, 161, 152, 0.2));
}

/* GET IN TOUCH */
.get-in-touch-bg {
  background: linear-gradient(118.65deg, #23a198 20.26%, #3ec8be 80.25%);
  box-shadow: 0px 9px 99px 0px #23a19899;
}

/* ABOUT US */

.aboutusimg {
  filter: drop-shadow(0px 35px 65px #23a19833);
}

/* CONTACT US */

.contact-form ::placeholder {
  color: #cecece !important;
}
textarea {
  resize: none !important;
}
/* BEST FEATURE SECTION */
@media (min-width: 991.99px) {
  .best-feature-sec .slick-dots {
    left: 68%;
    bottom: 12% !important;
  }
}
@media (min-width: 991.99px) {
  .sec-feedback .slick-dots {
    left: -131%;
    bottom: 12% !important;
  }
}
