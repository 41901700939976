@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --fs-2xsm: 14px;
  --fs-xxsm: 16px;
  --fs-xsm: 18px;
  --fs-sm: 19px;
  --fs-md: 20px;
  --fs-lg: 22px;
  --fs-xl: 30px;
  --fs-xxl: 32px;
  --fs-2xl: 42px;
  --fs-3xl: 46px;
}

.fs_2xsm {
  font-size: var(--fs-2xsm);
}
.fs_xxsm {
  font-size: var(--fs-xxsm);
}
.fs_xsm {
  font-size: var(--fs-xsm);
}
.fs_sm {
  font-size: var(--fs-sm);
}
.fs_md {
  font-size: var(--fs-md);
}
.fs_lg {
  font-size: var(--fs-lg);
}
.fs_xl {
  font-size: var(--fs-xl);
}
.fs_xxl {
  font-size: var(--fs-xxl);
}
.fs_2xl {
  font-size: var(--fs-2xl);
}
.fs_3xl {
  font-size: var(--fs-3xl);
}

@media (min-width: 575.99px) {
  :root {
    --fs-2xsm: 14px;
    --fs-xxsm: 16px;
    --fs-xsm: 18px;
    --fs-sm: 20px;
    --fs-md: 23px;
    --fs-lg: 24px;
    --fs-xl: 36px;
    --fs-xxl: 40px;
    --fs-2xl: 46px;
    --fs-3xl: 60px;
  }
}
@media (min-width: 767.99px) {
  :root {
    --fs-2xsm: 14px;
    --fs-xxsm: 16px;
    --fs-xsm: 17px;
    --fs-sm: 19px;
    --fs-md: 21px;
    --fs-lg: 24px;
    --fs-xl: 36px;
    --fs-xxl: 44px;
    --fs-2xl: 48px;
    --fs-3xl: 60px;
  }
}
@media (min-width: 991.99px) {
  :root {
    --fs-2xsm: 14px;
    --fs-xxsm: 16px;
    --fs-xsm: 17px;
    --fs-sm: 19px;
    --fs-md: 21px;
    --fs-lg: 24px;
    --fs-xl: 36px;
    --fs-xxl: 46px;
    --fs-2xl: 48px;
    --fs-3xl: 60px;
  }
}
@media (min-width: 1199.99px) {
  :root {
    --fs-2xsm: 14px;
    --fs-xxsm: 16px;
    --fs-xsm: 18px;
    --fs-sm: 20px;
    --fs-md: 24px;
    --fs-lg: 28px;
    --fs-xl: 36px;
    --fs-xxl: 46px;
    --fs-2xl: 48px;
    --fs-3xl: 64px;
  }
}
@media (min-width: 1399.99px) {
  :root {
    --fs-2xsm: 14px;
    --fs-xxsm: 16px;
    --fs-xsm: 18px;
    --fs-sm: 20px;
    --fs-md: 24px;
    --fs-lg: 28px;
    --fs-xl: 36px;
    --fs-xxl: 48px;
    --fs-2xl: 54px;
    --fs-3xl: 72px;
  }
}
